body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.margin {
  margin-top: 30px;
}
.center {
  text-align: center;
}
.border {
  display: inline-block;
  width: 350px;
  height: 300px;
  margin: 6px;
}
.image {
  display: flex;
}
.preview {
  margin-top: 75px;
}
.image > * {
  display: flex;
  height: 400px;
  width: 400px;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}
.smloader,
.smloader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.smloader {
  margin: 0 auto;
  top: 40%;
  left: 47%;
  font-size: 10px;
  position: fixed;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#smloadingDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #8888888f;
  z-index: 5300;
}
.hero-section {
  padding-top: 30px;
  padding-bottom: 30px;
}
.mainLogo {
  position: absolute;
  width: 125px;
  background: #ffffff;
  margin: 10px;
  padding: 5px;
}
.navbar-toggler span {
  background-color: #000;
}
.navbar-phone {
  font-size: 46px;
  padding-top: 5px;
}
.navbar-phone-no {
  color: #333333;
  background-color: transparent;
  transition: 0.5s;
  box-shadow: none;
  text-decoration: none;
}
.navbar-phone-no:hover {
  color: #074ece;
}
.footer {
  background-color: #0d1d3b;
  color: #fff;
  padding: 0;
  padding-bottom: 25px;
}
.copyright a:first-child {
  margin-left: 15px;
}
.copyright a {
  color: #fff;
  text-decoration: underline;
}
.copyright a:hover {
  color: #4ac728;
}

.height {
  max-height: 100%;
  height: 100%;
  min-height: 320px;
}
.contact {
  font-size: xxx-large;
}
.place {
  font-size: 20px;
}
.heading {
  min-height: 18%;
}
.content {
  height: 60%;
  padding-bottom: 4px;
  font-size: 18px;
  line-height: normal;
}
.dis {
  max-width: 100% !important;
  height: auto !important;
}
.style {
  height: 400px;
  width: 400px;
}
@media (max-width: 991px) {
  .height {
    max-height: 100%;
    height: 100%;
    min-height: 220px;
  }
  .heading {
    min-height: 14%;
  }
  .content {
    height: 46%;
    padding-bottom: 4px;
    font-size: 18px;
    line-height: normal;
  }
}
.contact {
  font-size: 3.25rem !important;
}

/* term condition */
.heading-sc {
  font-size: 55px;
  color: #333;
  font-weight: 600;
  margin-bottom: 0px;
}
.accept-terms {
  font-size: 28px;
  font-weight: 600;
  text-shadow: 0px 0px 0px rgb(0 0 0 / 30%);
  color: #333;
}
.accept-terms-para {
  color: #333333;
  font-family: "Source Serif Pro", Sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
}

.terms-condition li::marker {
  color: #285488;
}
.contentsec {
  padding-left: 0px;
  padding-right: 0px;
}
